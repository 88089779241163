'use client'

import { Error } from '@mui/icons-material'
import { Alert, TextField } from '@mui/material'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'

import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { trpc } from '@/modules/api/trpc/lib/browser'
import { Button } from '@/modules/ui/components/button'

import classes from './sign-up-form.module.scss'
import { useSignUpMutation } from '../../hooks/use-sign-up-mutation'
import { PASSWORD_MIN_LENGTH } from '../../lib/const'

export function SignUpForm() {
  const { logEvent } = useAnalytics()
  const t = useTranslations('auth')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const signUpMutation = useSignUpMutation({
    // There is additional handling of errors in the mutation therefore we use callback instead of signUpMutation.error
    onError: (error) => {
      setErrorMessage(error.message)
    },
  })

  const checkEmailMutation = trpc.session.isEmailAvailable.useMutation()

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    logEvent('sign_up_email_submit')

    const formData = new FormData(event.currentTarget)

    checkEmailMutation.mutate(
      {
        email: formData.get('email') as string,
      },
      {
        onSuccess: () => {
          signUpMutation.mutate({
            firstName: formData.get('firstName') as string,
            lastName: formData.get('lastName') as string,
            email: formData.get('email') as string,
            password: formData.get('password') as string,
          })
        },
        onError: (error) => {
          setErrorMessage(error.message)
        },
      }
    )
  }

  useEffect(() => {
    logEvent('welcome_page')
    logEvent('sign_up_email_page')
  }, [logEvent])

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      {errorMessage && (
        <Alert severity="error" icon={<Error />}>
          {errorMessage}
        </Alert>
      )}
      <div className={classes.namesRow}>
        <TextField
          required
          name="firstName"
          label={t('firstName')}
          placeholder={t('firstName')}
          size="small"
          slotProps={{
            input: { readOnly: signUpMutation.isPending },
          }}
        />
        <TextField
          required
          name="lastName"
          label={t('lastName')}
          placeholder={t('lastName')}
          size="small"
          slotProps={{
            input: { readOnly: signUpMutation.isPending },
          }}
        />
      </div>

      <TextField
        required
        name="email"
        type="email"
        label={t('email')}
        placeholder={t('email')}
        size="small"
        slotProps={{
          input: { readOnly: signUpMutation.isPending },
        }}
      />
      <TextField
        required
        name="password"
        type="password"
        label={t('password')}
        helperText={t('passwordHelperText', { minLength: PASSWORD_MIN_LENGTH })}
        placeholder={t('password')}
        size="small"
        slotProps={{
          input: { readOnly: signUpMutation.isPending },
          htmlInput: { minLength: PASSWORD_MIN_LENGTH },
        }}
      />

      <Button
        analytics={{
          eventName: 'join',
        }}
        type="submit"
        variant="contained"
        color="secondary"
        size="large"
        disabled={signUpMutation.isPending}
      >
        {t('join')}
      </Button>
    </form>
  )
}
