import { useRouter } from 'next/navigation'

import { ROUTES } from '@/config/routes'
import { useAnalytics } from '@/modules/analytics/hooks/use-analytics'
import { HAMMERTIME_API_ERRORS, isHammerTimeError } from '@/modules/api/hammer-time/lib/errors'
import { trpc } from '@/modules/api/trpc/lib/browser'

export function useSignUpMutation(mutationOptions?: Parameters<typeof trpc.auth.signUp.useMutation>[0]) {
  const { identifyUser, logEvent } = useAnalytics()
  const router = useRouter()

  return trpc.auth.signUp.useMutation({
    ...mutationOptions,
    onError: (...args) => {
      const error = args[0]
      if (isHammerTimeError(error.shape?.data)) {
        const hammertimeErrors = error.shape.data.hammertimeError

        if (hammertimeErrors?.some((error) => error.code === HAMMERTIME_API_ERRORS.AUTH.MISSING_FIELD)) {
          return router.push(ROUTES.CREATE_ACCOUNT)
        }
      }

      mutationOptions?.onError?.(...args)
    },
    onSuccess: (...args) => {
      const data = args[0]

      logEvent('auth_success', {
        user_id: data.sessionUser.id,
        type: 'email',
        new_user: 'yes',
      })

      identifyUser(data.sessionUser.id)
      router.replace(ROUTES.PROTECTED.MEMBERSHIP)
      router.refresh()

      mutationOptions?.onSuccess?.(...args)
    },
  })
}
